// Here you can add other styles
.header-text {
    color: white;
}
@media (min-width: 768px) {
    .header-text {
        font-size: x-large;
    }
  }

  .control-required:after {
    content: "*";
    color: red;
    margin-left: 6px;
    font-size: 13px;
    font-weight: bold;
}



// .page-header {
//     margin-bottom: 1rem;
// }

// .goToRegister {
//     font-size: 2em;
//     float: right;
//     //color: $metallic-blue;
// }


.signin{
    background-color: #3c4b64;
  }
  
  .signin-text{
  
   // margin-right: 24rem;
    color: white;
    font-size: 50px;
  }

.signin-bodytext{
  
    font-size: 1 rem;
     color: grey;
   }

.error{
color:red
}

// .btn:disabled, .btn.disabled, fieldset:disabled .btn {
//   color: var(--cui-btn-disabled-color);
//   pointer-events: none;
//   background-color: #007bff;
//   border-color: var(--cui-btn-disabled-border-color, transparent);
//   opacity: 0.65;
// }

// .btnsignin {
//   color: #fff;
//   background-color: #3c4b64;
//   border-color: #005cbf;
//   --cui-btn-hover-bg: #3c4b64;
//   --cui-btn-hover-border-color: #005cbf;
// }
/*
a.hlink
 {
   color: rgba(6, 59, 139, 0.95);
    text-decoration: none;
}

a.hlink:hover {
    color: rgb(59, 55, 59);
    text-decoration: underline;
}
*/
