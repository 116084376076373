

.fullheader{
  background-color: #3c4b64;
}

.logo-text{

  margin-right: 24rem;
  color: white;
}