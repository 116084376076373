
 $white:     #fff !default;

 $primary-base:  #260ff7 !default;

 $primary:       $primary-base !default;


 $link-color:                              $primary !default;
 $link-decoration:                         none !default;

 $link-hover-decoration:                   underline !default;

$font-family-sans-serif:      "Segoe UI" !default;

 $sidebar-width:                            16rem !default;


 $sidebar-brand-height:                     12rem !default;

 $sidebar-nav-link-hover-bg:                rgba($white, .09) !default;

 $sidebar-nav-icon-height:                  1.1rem !default;

